import React from 'react'
import styled from 'styled-components'
import { Layout } from '../components/common'
import SEO from '../components/common/SEO'
import Header from '../components/theme/Header'
import Footer from '../components/theme/Footer'
import ApartmentsShort from '../components/common/Apartments/ApartmentsShort'
import TitleSection from '../components/common/TitleSection'

const comfort = {
	name: "Comfort",
	image: require('../../static/Comfort/front_short/front_short.jpg'),
	link: 'comfort'
}

const coral = {
	name: "Coral",
	image: require('../../static/Coral/front_short/front_short.jpg'),
	link: 'coral'
}

const marina = {
	name: "Marina",
	image: require('../../static/Marina/front_short/front_short.jpg'),
	link: 'marina'
}

const perfect = {
	name: "Perfect",
	image: require('../../static/Perfect/front_short/front_short.jpg'),
	link: 'perfect'
}

const MainContentHolder = styled.div`

`


const ApartmentsPage = () => (
	<Layout>
		<React.Fragment>
			<Header />
			<SEO title="apartments" />
			<MainContentHolder className="main-content-holder">
			<TitleSection section="Apartments" />
				<ApartmentsShort value={comfort} exerptT="exeprtComfort" />
				<ApartmentsShort value={coral} exerptT="exeprtCoral" />
				<ApartmentsShort value={marina} exerptT="exeprtMarina" />
				<ApartmentsShort value={perfect} exerptT="exeprtPerfect" />
			</MainContentHolder>
			<Footer />
		</React.Fragment>
	</Layout>
)

export default ApartmentsPage
